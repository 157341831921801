<template>
  <div>
    <h1 class="text-size-h1 text-bold mb-m">Статистика</h1>
  </div>
</template>

<script>
/**
 * @page Страница статистики по ученикам наставника
 */
export default {
  name: "MentorStats",
};
</script>

<style></style>
